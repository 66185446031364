import React, { useEffect } from 'react';
import RichContentViewer from '../rich-content-viewer/rich-content-viewer';
import PostContent from '../post-content/post-content';
import { flowRight } from 'lodash';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { connect } from '../../../common/components/runtime-context';
import {
  CATEGORY_GUIDELINES,
  getDisplayGuidelinesTypeFromStore,
  getGuidelinesContent,
} from '../../selectors/guidelines-selectors';
import PropTypes from 'prop-types';
import ViewportIosContainer from '../../containers/viewport-ios-container/viewport-ios-container';
import { ModalFromRight } from '../modals/base';
import { ReactComponent as Close } from 'wix-ui-tpa/dist/src/assets/icons/Close.svg';
import { classes } from './post-guidelines-modal.st.css';
import { Text } from 'wix-ui-tpa/Text';
import { IconButton } from 'wix-ui-tpa/IconButton';
import { Button } from 'wix-ui-tpa/Button';
import classNames from 'classnames';

const PostGuidelinesModal = ({ t, type, content, onClose, onShown, hasCategoryChanged, categoryId, isEdit }) => {

  useEffect(() => {
    onShown(
      hasCategoryChanged ? 'on_category_change' : isEdit ? 'edit' : 'create_post',
    );
  }, [categoryId, hasCategoryChanged, isEdit]);

  return (
    <ViewportIosContainer dataHook="guidelines-section" className={classes.viewport}>
      <ModalFromRight className={classes.modal} onClose={onClose}>
        <div className={classes.header}>
          <Text className={classes.title}>
            {t(
              type === CATEGORY_GUIDELINES
                ? 'guidelines.title.category-guidelines'
                : 'guidelines.title.forum-guidelines',
            )}
          </Text>
          <IconButton data-hook="guidelines-modal-close" onClick={onClose} icon={<Close />} />
        </div>

        <div className={classNames(classes.content, 'forum-text-color')}>
          <RichContentViewer
            initialState={content}
            readOnly
            origin="post"
            type={PostContent.POST_PAGE}
          />
        </div>

        <div
          className={classNames(
            classes.footer,
            'forum-card-background-color',
            'forum-card-border-color',
          )}
        >
          <Button className={classes.button} fullWidth priority="basic" onClick={onClose} size="medium">
            {t('guidelines.modal.okay-button')}
          </Button>
        </div>
      </ModalFromRight>
    </ViewportIosContainer>
  );
};

PostGuidelinesModal.propTypes = {
  t: PropTypes.func,
  type: PropTypes.string,
  categoryId: PropTypes.string,
  content: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onShown: PropTypes.func.isRequired,
  hasCategoryChanged: PropTypes.bool,
  isEdit: PropTypes.bool,
};

const mapRuntimeToProps = (state, props, actions) => {
  const type = getDisplayGuidelinesTypeFromStore(state, props.categoryId);
  return {
    content: getGuidelinesContent(state)(props.categoryId),
    type,
    onShown: (origin) => actions.userEventsGuidelinesShown({ type, origin, categoryId: props.categoryId }),
  };
};

export default flowRight(connect(mapRuntimeToProps), withTranslate)(PostGuidelinesModal);
